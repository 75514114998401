.image-upload {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 160px;
  border: 1px solid $border-color;
  border-radius: 6px;

  &.lg {
    width: 100%;
    height: auto;
    padding-bottom: 130%;
  }

  &.disabled {
    background-color: $input-disabled-bg;
    pointer-events: none;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    z-index: 20;
    cursor: pointer;
    opacity: 0;
  }

  label {
    margin: 0;
    display: block;
  }

  span {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(white, 0.7);
    border-radius: 6px;
    transition: opacity 0.3s;
    color: $border-color;

    i {
      font-size: 2rem;
    }

    &.empty {
      opacity: 1;
    }

    &.image-loading {
      opacity: 1;
    }
  }

  .preloader {
    height: auto;
  }

  &.empty {
    border-style: dashed;
    border-width: 2px;
  }

  // &:hover:not(.disabled) {
  //   span {
  //     opacity: 1;
  //   }
  // }
}

.delete-image {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 22;
  background: red;
  color: white;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background: red;
    color: white;
    opacity: 0.8;
  }
}

.edit-image {
  position: absolute;
  top: 5px;
  right: 40px;
  z-index: 22;

  &:hover {
    background: #eee;
    color: white;
    opacity: 0.8;
  }
}